import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import { Col, Container, Row, Table, Pagination } from 'react-bootstrap';
import { FiUpload } from 'react-icons/fi';
import Papa from 'papaparse';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, onSnapshot } from 'firebase/firestore';
const Preloader = () => (
    <div className="loader"></div>
);
function ListUpload() {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage] = useState(10);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [user, setUser] = useState(null);
    const [credits, setCredits] = useState(0);
    const navigate = useNavigate();
    const apiKey = process.env.REACT_APP_API_KEY;

    const findClosestColumn = (labels, columns) => {
        const regex = new RegExp(`\\b(${labels.join('|')})\\b`, 'i');
        const matchedColumn = columns.find(col =>
            regex.test(col.toLowerCase()) && !col.toLowerCase().includes('mailing')
        );

        return matchedColumn ? matchedColumn : "";
    };
    const findClosestColumnRest = (label, columns) => {
        const matchedColumn = columns.find(col => col.toLowerCase().includes(label.toLowerCase()));

        return matchedColumn ? matchedColumn : "";
    };



    useEffect(() => {
        const auth = getAuth();
        const db = getFirestore();

        const unsubscribeAuth = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
                const userDocRef = doc(db, 'userProfiles', currentUser.uid);
                const unsubscribeSnapshot = onSnapshot(userDocRef, (docSnapshot) => {
                    if (docSnapshot.exists()) {
                        setCredits(docSnapshot.data().credits || 0);
                    }
                });

                return () => unsubscribeSnapshot();
            } else {
                setUser(null);
            }
        });

        if (tableData.length > 0) {
            const columns = Object.keys(tableData[0]);
            setData({
                owner1FirstName: findClosestColumnRest('first', columns),
                owner1LastName: findClosestColumnRest('last', columns),
                owner2FirstName: findClosestColumnRest('owner 2 first name', columns),
                owner2LastName: findClosestColumnRest('owner 2 last name', columns),
                street: findClosestColumn(['address', 'property address'], columns),
                city: findClosestColumn(['city', 'property city'], columns),
                state: findClosestColumn(['state', 'property state'], columns),
                zip: findClosestColumn(['zip', 'zip code', 'property zip code', 'zip_code'], columns),
                altStreet: findClosestColumnRest('mailing address', columns),
                altCity: findClosestColumnRest('mailing city', columns),
                altState: findClosestColumnRest('mailing state', columns),
                altZip: findClosestColumnRest('mailing zip', columns),
            });
        }
        return () => unsubscribeAuth();

    }, [tableData]);


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        Papa.parse(file, {
            header: true,
            dynamicTyping: true,
            complete: (result) => {
                if (result.data.length > 27500) {
                    Swal.fire({
                        icon: 'error',
                        title: 'File too large',
                        text: 'The file contains more than 27,500 records.',
                        customClass: {
                            confirmButton: 'my-confirm-button',
                        }
                    });
                    setTableData([]);
                } else {
                    setTableData(result.data);
                    setSelectedColumns(Object.keys(result.data[0] || {}));
                }
            }
        });
    };

    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = tableData.slice(indexOfFirstRow, indexOfLastRow);

    const totalPages = Math.ceil(tableData.length / rowsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const [data, setData] = useState({
        owner1FirstName: "",
        owner1LastName: "",
        owner2FirstName: "",
        owner2LastName: "",
        street: "",
        city: "",
        state: "",
        zip: "",
        altStreet: "",
        altCity: "",
        altState: "",
        altZip: "",
    });

    useEffect(() => {
        const uid = localStorage.getItem("uid");
        if (!uid) {
            navigate('/SignIn');
        }
    }, [navigate]);

    const onInputChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        if (credits === 0) {
            navigate('/pricing');
        } else {



            if (tableData.length === 0) {
                Swal.fire({
                    icon: 'error',
                    title: 'No Data',
                    text: 'There is no data to submit.',
                    customClass: {
                        confirmButton: 'my-confirm-button',
                    }

                });
                return;
            }
            setIsSubmitting(true);

            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('owner1FirstName', data.owner1FirstName);
            formData.append('owner1LastName', data.owner1LastName);
            formData.append('owner2FirstName', data.owner2FirstName);
            formData.append('owner2LastName', data.owner2LastName);
            formData.append('street', data.street);
            formData.append('city', data.city);
            formData.append('state', data.state);
            formData.append('zip', data.zip);
            formData.append('altStreet', data.altStreet);
            formData.append('altCity', data.altCity);
            formData.append('altState', data.altState);
            formData.append('altZip', data.altZip);
            formData.append('uid', localStorage.getItem("uid"));

            try {
                const response = await axios.post("https://api.v1.badskip.com/skiptracing/upload", formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'x-api-key': apiKey
                    }
                });

                if (response.data && response.data.file_id && response.data.user_id) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Processing',
                        text: "File successfully processed.",
                        timer: 1000,
                        customClass: {
                            confirmButton: 'my-confirm-button',
                        }
                    }).then(() => navigate("/skiptracing"));
                    const { file_id, user_id } = response.data;

                    const statusData = {
                        fileId: file_id,
                        uid: user_id
                    };

                    await axios.post("https://api.v1.badskip.com/skiptracing/process", statusData, {
                        headers: {
                            'Content-Type': 'application/json',
                            'x-api-key': apiKey
                        }
                    });


                    setIsSubmitting(false);
                } else {
                    setIsSubmitting(false);
                    Swal.fire({
                        icon: 'error',
                        title: 'Something went wrong',
                        text: 'Please try again.',
                        customClass: {
                            confirmButton: 'my-confirm-button',
                        }
                    });
                }
            } catch (error) {
                setIsSubmitting(false);
                if (!error.response?.status === 503) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something went wrong',
                        text: error.response?.data?.message || 'An error occurred. Please try again.',
                        customClass: {
                            confirmButton: 'my-confirm-button',
                        }
                    });
                }
            }
        }
    };

    const renderPagination = () => {
        const pageNumbers = [];
        const maxPagesToShow = 8;
        let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
        let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

        if (endPage - startPage < maxPagesToShow - 1) {
            startPage = Math.max(1, endPage - maxPagesToShow + 1);
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
                    {i}
                </Pagination.Item>
            );
        }

        return (
            <Pagination>
                <Pagination.Prev disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)} />
                {pageNumbers}
                <Pagination.Next disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)} />
            </Pagination>
        );
    };


    return (
        <>
            <Helmet>
                <title>BadSkip | Precision Skiptracing Services
                </title>
            </Helmet>
            <section className='listUpload'>
                <form method="post" onSubmit={(e) => onSubmit(e)} encType='multipart/form-data'>
                    <Container>
                        <Row className='justify-content-between  my-5 px-5'>
                            <Col md={4} sm={4}>
                                <div className='list'>
                                    <img src="images/liost.svg" alt="upload-list" />
                                    <h3>Your List</h3>
                                </div>
                            </Col>
                            <Col md={3} sm={3} className='uploaddiv'>
                                <div className='uploadInput'>
                                    <input
                                        type="file"
                                        name='file'
                                        id="fileInput"
                                        onChange={handleFileChange}
                                        style={{ display: "none" }}
                                        accept='.csv'
                                    />
                                    <label htmlFor="fileInput" className="custom-button">

                                        <FiUpload />
                                        Upload

                                    </label>
                                </div>
                                <p>Please Make Sure to Upload a CSV File</p>
                            </Col>
                        </Row>

                        {/* Display Table if CSV is uploaded */}
                        {selectedFile && tableData.length > 0 && (
                            <Row className='my-5 tableShowList'>
                                <Col md={9} sm={12}>
                                    <div className='table-responsive'>
                                        <Table hover>
                                            <thead>
                                                <tr>
                                                    {selectedColumns.map((header, index) => (
                                                        <th key={index}>{header}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentRows.map((row, rowIndex) => (
                                                    <tr key={rowIndex}>
                                                        {selectedColumns.map((col, colIndex) => (
                                                            <td key={colIndex}>{row[col]}</td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>

                                    </div>
                                    {renderPagination()}

                                </Col>
                                <Col md={3} sm={12}>
                                    <div className='categoryList'>
                                        <div className='head'>
                                            <p>Category</p>
                                            <p>Column to use</p>
                                        </div>
                                        <div className="d-flex">
                                            <label>
                                                Owner 1 First Name
                                            </label>
                                            <select
                                                name='owner1FirstName'
                                                value={data.owner1FirstName}
                                                onChange={onInputChange}
                                            >
                                                <option value="" readonly disabled></option>

                                                {Object.keys(tableData[0]).map((col, index) => (
                                                    <option key={index} value={col}>{col}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="d-flex">
                                            <label>
                                                Owner 1 Last Name
                                            </label>
                                            <select
                                                name='owner1LastName'
                                                value={data.owner1LastName}
                                                onChange={onInputChange}
                                            >
                                                <option value="" readonly disabled></option>

                                                {Object.keys(tableData[0]).map((col, index) => (
                                                    <option key={index} value={col}>{col}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="d-flex">
                                            <label>
                                                Owner 2 First Name
                                            </label>
                                            <select
                                                name='owner2FirstName'
                                                value={data.owner2FirstName}
                                                onChange={onInputChange}
                                            >
                                                <option value="" readonly disabled></option>
                                                {Object.keys(tableData[0]).map((col, index) => (
                                                    <option key={index} value={col}>{col}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="d-flex">
                                            <label>
                                                Owner 2 Last Name
                                            </label>
                                            <select
                                                name='owner2LastName'
                                                value={data.owner2LastName}
                                                onChange={onInputChange}
                                            >
                                                <option value="" readonly disabled> </option>

                                                {Object.keys(tableData[0]).map((col, index) => (
                                                    <option key={index} value={col}>{col}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="d-flex">
                                            <label>
                                                Property Address
                                            </label>
                                            <select
                                                name='street'
                                                value={data.street}
                                                onChange={onInputChange}
                                            >
                                                <option value="" readonly disabled> </option>

                                                {Object.keys(tableData[0]).map((col, index) => (
                                                    <option key={index} value={col}>{col}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="d-flex">
                                            <label>
                                                Property City
                                            </label>
                                            <select
                                                name='city'
                                                value={data.city}
                                                onChange={onInputChange}
                                            >
                                                <option value="" readonly disabled> </option>

                                                {Object.keys(tableData[0]).map((col, index) => (
                                                    <option key={index} value={col}>{col}</option>
                                                ))}
                                            </select>
                                        </div><div className="d-flex">
                                            <label>
                                                Property State
                                            </label>
                                            <select
                                                name='state'
                                                value={data.state}
                                                onChange={onInputChange}
                                            >
                                                <option value="" readonly disabled> </option>

                                                {Object.keys(tableData[0]).map((col, index) => (
                                                    <option key={index} value={col}>{col}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="d-flex">
                                            <label>
                                                Property Zip Code
                                            </label>
                                            <select
                                                name='zip'
                                                value={data.zip}
                                                onChange={onInputChange}
                                            >
                                                <option value="" readonly disabled>  </option>

                                                {Object.keys(tableData[0]).map((col, index) => (
                                                    <option key={index} value={col}>{col}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="d-flex">
                                            <label>
                                                Mailing Address
                                            </label>
                                            <select
                                                name='altStreet'
                                                value={data.altStreet}
                                                onChange={onInputChange}
                                            >
                                                <option value="" readonly disabled> </option>

                                                {Object.keys(tableData[0]).map((col, index) => (
                                                    <option key={index} value={col}>{col}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="d-flex">
                                            <label>
                                                Mailing City
                                            </label>
                                            <select
                                                name='altCity'
                                                value={data.altCity}
                                                onChange={onInputChange}
                                            >
                                                <option value="" readonly disabled> </option>

                                                {Object.keys(tableData[0]).map((col, index) => (
                                                    <option key={index} value={col}>{col}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="d-flex">
                                            <label>
                                                Mailing State
                                            </label>
                                            <select
                                                name='altState'
                                                value={data.altState}
                                                onChange={onInputChange}
                                            >
                                                <option value="" readonly disabled> </option>

                                                {Object.keys(tableData[0]).map((col, index) => (
                                                    <option key={index} value={col}>{col}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="d-flex">
                                            <label>
                                                Mailing Zip
                                            </label>
                                            <select
                                                name='altZip'
                                                value={data.altZip}
                                                onChange={onInputChange}
                                            >
                                                <option value="" readonly > </option>

                                                {Object.keys(tableData[0]).map((col, index) => (
                                                    <option key={index} value={col}>{col}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        )}

                        {!selectedFile ?
                            <>
                                <div className='uploadDiv'>
                                    <img src="images/Group (5).png" alt="upload-list" />
                                    <h3>No List Uploaded</h3>
                                    <p>Upload a List to Start The Process</p>
                                </div>
                                <div className='listIns'>
                                    <h3>How it Works:</h3>
                                    <ol>
                                        <li><span>Prepare Your List:  </span> Ensure your list is in a compatible format (CSV).</li>
                                        <li><span>Upload Your List:  </span> Click the "Upload Your List" button and select your file.</li>
                                        <li><span>Review and Confirm:  </span> Check the details and confirm the upload.</li>
                                        <li><span>Processing:  </span> Our system processes your list for accurate skip tracing.</li>
                                        <li><span>Results: </span> Receive your skip tracing results via email or directly on our platform.</li>
                                    </ol>
                                    <p>
                                        Our skip-tracing service delivers actionable insights without unnecessary noise, allowing you to connect directly with the people who matter most to your business.
                                        Every lead is treated as a valuable asset, and our commitment to accuracy ensures that you're always working with the most up-to-date and reliable information available.
                                    </p>
                                    <div className='listbtn'>
                                        <input
                                            type="file"
                                            name='file'
                                            id="fileInput"
                                            onChange={handleFileChange}
                                            style={{ display: "none" }}
                                            accept='.csv'
                                        />
                                        <label htmlFor="fileInput" className="custom-button">
                                            {selectedFile ?
                                                <div className="file-name">
                                                    {selectedFile.name}
                                                </div>
                                                : <>
                                                    Upload Your List Now
                                                </>}
                                        </label>
                                    </div>
                                </div>
                            </>
                            : <>
                                <div className='listbtn'>
                                    <button type="submit" disabled={isSubmitting || tableData.length === 0}>  {isSubmitting ? <Preloader /> : "Start"}</button>

                                </div>
                            </>}


                    </Container>
                </form>
            </section>

            <Footer />
        </>
    );
}

export default ListUpload;
